import React, { memo } from "react";

const EmbedVideoMovie = ({ id }) => {

  return (
	<div className="watch-movie">
		<MyPureIframe
			src={`https://www.2embed.cc/embed/${id}`} 
			width="100%"
			height={"100%"}
			title="Movie player"
			frameBorder="0"
			allowFullScreen
		 />
	</div>
  );
};

const MyPureIframe = memo(({src, width, height, ...whateverProps}) => (
  <iframe title="Movie player" src={src} width={width} height={height} {...whateverProps}/>
));

export default EmbedVideoMovie;
