import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Title = ({ title = "Phim Moi | Watching and stream movie free", description='Phimmoi tutorial-aws' }) => {
  
	if (description){
		title += `| ${description}`;
	}
  useEffect(() => {
    document.title = title;
  });

  return <Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="keywords" content="phimmoi, phimmoi tutorial-aws, tutorial-aws, xem phim, watch film" />
			<meta name="author" content="Phimmoi" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content="./icon.png" />
			<meta property="og:url" content="https://phimmoi.tutorial-aws.com" />
		</Helmet>;
};

export default Title;
